import { httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { getFbFunctions } from './development';

export async function sendContactForm(context, { name, email, message, token }) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    // Verify captcha
    const contactForm = httpsCallable(functions, 'contactForm');
    var data = {
      name: name,
      email: email,
      message: message,
      token: token,
    };
    return await contactForm(data);
  } catch {
    return false;
  }
}
