import { httpsCallable } from 'firebase/functions';
import { getFbFunctions } from './development';
import {
  updateDoc,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

import { getApp } from 'firebase/app';

export async function generateApiKey(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    const generateApiKey = httpsCallable(functions, 'generateApiKey');
    return (await generateApiKey()).data;
  } catch {
    return null;
  }
}

export async function getApiAuthMethods(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  const docRef = doc(db, 'users', this.state.user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (docSnap.data().apiAuthMethods == null) {
      return 'none';
    } else {
      return docSnap.data().apiAuthMethods;
    }
  } else {
    return 'none';
  }
}

export async function changeApiAuthMethods(context, apiAuthMethods) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  try {
    await updateDoc(doc(db, 'users', this.state.user.uid), {
      apiAuthMethods: apiAuthMethods,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getApiKey(context) {
  if (!this.state.user) {
    return null;
  }

  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  const docRef = doc(db, 'users', this.state.user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (docSnap.data().apiKey == null) {
      return await context.dispatch('generateApiKey');
    } else {
      return docSnap.data().apiKey;
    }
  } else {
    return null;
  }
}

export async function getSearchKey(context) {
  if (!this.state.user) {
    return null;
  }

  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  const docRef = doc(db, 'users', this.state.user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (docSnap.data().algoliaKey == null) {
      const generateApiKey = httpsCallable(functions, 'generateAlgoliaUserKey');
      var key = await generateApiKey();
      if (key.data) {
        context.commit('setSearchKey', key.data);
        return key.data;
      } else {
        return null;
      }
    } else {
      context.commit('setSearchKey', docSnap.data().algoliaKey);
      return docSnap.data().algoliaKey;
    }
  } else {
    return null;
  }
}

export async function getTeamSearchKey(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', this.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().algoliaKey == null) {
        const generateApiKey = httpsCallable(functions, 'generateAlgoliaTeamKey');
        var key = await generateApiKey();
        if (key.data) {
          let teamData = context.state.teamData;
          teamData.algoliaKey = key.data;
          context.commit('setTeamData', teamData);
          return key.data;
        } else {
          return null;
        }
      } else {
        let teamData = context.state.teamData;
        teamData.algoliaKey = docSnap.data().algoliaKey;
        context.commit('setTeamData', teamData);
        return docSnap.data().algoliaKey;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}
