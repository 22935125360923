import { createApp } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';

import store from '@/api/index.js';
import '@/assets/styles/preflight.css';
import '@/assets/styles/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  Button,
  ConfigProvider,
  Spin,
  Result,
  Tag,
  Card,
  Empty,
  Skeleton,
  Radio,
  Tooltip,
  Affix,
  Menu,
  Dropdown,
  Row,
  Col,
  Form,
  Tabs,
  Upload,
  Select,
  Slider,
  Input,
  InputNumber,
  Breadcrumb,
  Table,
  Popover,
  Checkbox,
  Modal,
  Switch,
  List,
  Statistic,
  Typography,
  FloatButton,
  Space,
  Steps,
  Alert,
  Progress,
} from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import InstantSearch from 'vue-instantsearch/vue3/es';

// mouting point for the whole app
import App from '@/App.vue';
import VueKonva from 'vue-konva';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { minimatch } from 'minimatch';

// routes
const routes = [
  {
    path: '/app',
    redirect: '/app/dashboard',
    component: () => import('./layouts/AppLayout.vue'),
    meta: {
      authRequired: true,
    },
    beforeEnter: (to, from, next) => {
      let hjsBaseURL = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/';

      // If dark mode is enabled but the page is not dark, reload the page
      if (store.state.userPreferences.darkMode) {
        if (!document.body.classList.contains('dark')) {
          document.body.classList.add('dark');
        }
        if (document.getElementById('highlightStylesheet').href != `${hjsBaseURL}styles/dark.min.css`) {
          document.getElementById('highlightStylesheet').href = `${hjsBaseURL}styles/dark.min.css`;
        }
      } else {
        if (document.body.classList.contains('dark')) {
          document.body.classList.remove('dark');
        }
        if (document.getElementById('highlightStylesheet').href != `${hjsBaseURL}styles/default.min.css`) {
          document.getElementById('highlightStylesheet').href = `${hjsBaseURL}styles/default.min.css`;
        }
      }
      next();
    },
    children: [
      {
        path: '/app/dashboard',
        component: () => import('./views/app/DashboardView.vue'),
        name: 'Dashboard',
      },
      {
        path: '/app/settings',
        component: () => import('./views/app/SettingsView.vue'),
        name: 'Settings',
      },
      {
        path: '/app/search',
        component: () => import('./views/app/SearchView.vue'),
        name: 'Search Results',
      },
      {
        path: '/app/settings/billing',
        component: () => import('./views/app/LoadingView.vue'),
        name: 'Billing',
      },
      {
        path: '/app/inventory',
        component: () => import('./views/app/TablesView.vue'),
        name: 'Inventory',
      },
      {
        path: '/app/racks',
        name: 'Racks',
        component: () => import('./views/app/RackView.vue'),
      },
      {
        path: '/app/racks/:id',
        redirect: (to) => {
          return {
            path: '/app/racks/' + to.params.id + '/edit',
          };
        },
      },
      {
        path: '/app/racks/:id/edit',
        name: 'Manage Rack',
        component: () => import('./views/app/RackManageView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.teamData && store.state.userMode == 'team' && store.state.teamData.role == 'viewer') {
            next({
              path: '/app/racks/' + to.params.id + '/view',
            });
          } else {
            next();
          }
        },
      },
      {
        path: '/app/racks/:id/view',
        name: 'View Rack',
        component: () => import('./views/app/RackReadView.vue'),
      },
      {
        path: '/app/rooms',
        name: 'Rooms',
        component: () => import('./views/app/RoomView.vue'),
      },
      {
        path: '/app/rooms/:id',
        redirect: (to) => {
          return {
            path: '/app/rooms/' + to.params.id + '/edit',
          };
        },
      },
      {
        path: '/app/rooms/:id/edit',
        name: 'Manage Room',
        component: () => import('./views/app/RoomManageView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.teamData && store.state.userMode == 'team' && store.state.teamData.role == 'viewer') {
            next({
              path: '/app/rooms/' + to.params.id + '/view',
            });
          } else {
            next();
          }
        },
      },
      {
        path: '/app/rooms/:id/view',
        name: 'View Room',
        component: () => import('./views/app/RoomReadView.vue'),
      },
      {
        path: '/app/types',
        name: 'Types',
        component: () => import('./views/app/TypeView.vue'),
      },
    ],
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    component: () => import('./layouts/AdminLayout.vue'),
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    children: [
      {
        path: '/admin/dashboard',
        component: () => import('./views/admin/DashboardView.vue'),
        name: 'Admin Dashboard',
      },
      {
        path: '/admin/moderation',
        component: () => import('./views/admin/ModerationView.vue'),
        name: 'Slot Type Moderation',
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: () => import('./layouts/AuthLayout.vue'),
    meta: {
      toDash: true,
    },
    children: [
      {
        path: '/auth/login',
        component: () => import('./views/auth/LoginView.vue'),
      },
      {
        path: '/auth/register',
        component: () => import('./views/auth/RegisterView.vue'),
      },
      {
        path: '/auth/invite',
        component: () => import('./views/auth/InviteView.vue'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: () => import('./layouts/AuthLayout.vue'),
    children: [
      {
        path: '/auth/reset',
        component: () => import('./views/auth/ResetPasswordView.vue'),
      },
      {
        path: '/auth/verifyChange',
        component: () => import('./views/auth/verifyChangeView.vue'),
      },
      {
        path: '/auth/recoverEmail',
        component: () => import('./views/auth/revertEmailView.vue'),
      },
      {
        path: '/auth/verifyEmail',
        component: () => import('./views/auth/verifyEmailView.vue'),
      },
    ],
  },
  {
    path: '/pricing',
    component: () => import('./views/PricingView.vue'),
  },
  {
    path: '/faq',
    component: () => import('./views/RedirectView.vue'),
    props: {
      target: 'FAQ',
    },
    beforeEnter() {
      window.location.href = 'https://support.rackmanage.io/page/knowledgebase';
    },
  },
  {
    path: '/privacy',
    component: () => import('./views/RedirectView.vue'),
    props: {
      target: 'Privacy Policy',
    },
    beforeEnter() {
      window.location.href = 'https://support.rackmanage.io/space/RMS/5210263/Privacy+Policy';
    },
  },
  {
    path: '/terms',
    component: () => import('./views/RedirectView.vue'),
    props: {
      target: 'Terms of Service',
    },
    beforeEnter() {
      window.location.href = 'https://support.rackmanage.io/space/RMS/5243059/Terms+%26+Conditions';
    },
  },
  {
    path: '/cookies',
    component: () => import('./views/RedirectView.vue'),
    props: {
      target: 'Cookie Policy',
    },
    beforeEnter() {
      window.location.href = 'https://support.rackmanage.io/space/RMS/106004532/Cookie+Policy';
    },
  },
  {
    path: '/subprocessors',
    component: () => import('./views/RedirectView.vue'),
    props: {
      target: 'Subprocessors',
    },
    beforeEnter() {
      window.location.href = 'https://support.rackmanage.io/space/RMS/2949121/Subprocessors';
    },
  },
  {
    path: '/cookie-consent',
    component: () => import('./views/ManageCookiesView.vue'),
  },
  {
    path: '/',
    component: () => import('./views/IndexView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./views/404ErrorView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If authorizedDomains is set and the browser still has an array, replace it with a glob string
  if (store.state.authorizedDomains && Array.isArray(store.state.authorizedDomains)) {
    await store.dispatch('getAuthorizedDomains');
  }

  let continueURL = to.query.continueUrl;

  try {
    if (continueURL) {
      continueURL = new URL(continueURL);
    }
  } catch {
    continueURL = null;
  }

  // If continueURL and oobCode are set and we are on a different server, redirect to the continueURL
  if (continueURL && continueURL.hostname && to.query.oobCode && window.location.hostname != continueURL.hostname) {
    let newUrl;
    try {
      newUrl = new URL(`https://${continueURL.hostname}${to.fullPath}`);
    } catch {
      newUrl = continueURL;
    }
    window.location = newUrl.toString();

  } else {
    store.commit('setCurDomain', window.location.hostname);
    var devDomain = window.location.hostname.endsWith('.' + import.meta.env.VITE_DEV_DOMAIN);
    // Start by checking if domain exists in SaaS DB or is localhost / primary domain
    if (
      window.location.hostname == 'localhost' ||
      window.location.hostname == import.meta.env.VITE_HOSTNAME ||
      devDomain ||
      await store.dispatch('checkDomain', window.location.hostname)
    ) {
      // Update Firebase Domain
      await store.dispatch('initializeFirebase');

      // If user is signed out
      if (!store.state.user) {
        await store.commit('resetColorPreferences');
        if (store?.state?.checkedDomain?.data?.colors?.loginBackground) {
          let primaryColors = store.state.checkedDomain.data.colors.primary;
          let bgColors = store.state.checkedDomain.data.colors.loginBackground;
          let loginBackgroundString = bgColors.red + ' ' + bgColors.green + ' ' + bgColors.blue;
          let primaryString = primaryColors.red + ' ' + primaryColors.green + ' ' + primaryColors.blue;
          document.querySelector(':root').style.setProperty('--color-login-background', loginBackgroundString);
          document.querySelector(':root').style.setProperty('--color-primary', primaryString);
        }
        store.commit('setLoading', false);
        // If user is trying to access a protected page, redirect to login
        if (to.matched.some((record) => record.meta.authRequired)) {
          next({
            path: '/auth/login',
            query: { redirect: to.fullPath },
          });
        } else {
          next();
        }
      }
      // If user is signed in
      else {
        var emailUnverified = true;

        if (store.state.user.emailVerified) {
          emailUnverified = false;
        } else {
          var containsEmailPassword = false;
          for (var i = 0; i < store.state.user.providerData.length; i++) {
            if (store.state.user.providerData[i].providerId == 'password') {
              containsEmailPassword = true;
            }
          }
          if (containsEmailPassword) {
            emailUnverified = true;
          } else {
            emailUnverified = false;
          }
        }

        // If user's token has expired or email is unverified, log them out
        if (
          store.state.user.stsTokenManager.expirationTime < Date.now()
          || (emailUnverified && to.matched.some((record) => record.meta.authRequired))
        ) {
          await store.dispatch('logout');
          await store.commit('resetColorPreferences');

          if (store?.state?.checkedDomain?.data?.colors?.loginBackground) {
            let primaryColors = store.state.checkedDomain.data.colors.primary;
            let bgColors = store.state.checkedDomain.data.colors.loginBackground;
            let loginBackgroundString = bgColors.red + ' ' + bgColors.green + ' ' + bgColors.blue;
            let primaryString = primaryColors.red + ' ' + primaryColors.green + ' ' + primaryColors.blue;
            document.querySelector(':root').style.setProperty('--color-login-background', loginBackgroundString);
            document.querySelector(':root').style.setProperty('--color-primary', primaryString);
          }

          store.commit('setLoading', false);
          if (to.matched.some((record) => record.meta.authRequired)) {
            next({
              path: '/auth/login',
              query: { redirect: to.fullPath },
            });
          } else {
            next();
          }
        }
        // If user is trying to access a protected page
        else if (to.matched.some((record) => record.meta.authRequired)) {
          store.commit('setLoading', false);
          // Check if domain is on users's authorized domains list
          if (minimatch(window.location.hostname, store.state.authorizedDomains)) {
            // If user is trying to access an admin page, check if they are an admin
            if (to.matched.some((record) => record.meta.adminRequired)) {
              let perms = await store.dispatch('getSystemPermissions');
              if (perms) {
                next();
              } else {
                window.console.log('User does not have admin permissions');
                next({
                  path: '/app/dashboard',
                });
              }
            } else {
              await store.dispatch('getColorPreferences');
              await store.dispatch('getUserPreferences');
              next();
            }
          } else {
            window.console.log(`User is not authorized to access ${window.location.hostname}`);

            await store.commit('resetColorPreferences');
            await store.dispatch('logout');
            window.location = 'https://' + import.meta.env.VITE_HOSTNAME;
          }
        }
        // If user is trying to access an auth page, redirect to dashboard
        else if (to.matched.some((record) => record.meta.toDash)) {
          await store.dispatch('getColorPreferences');
          store.commit('setLoading', false);
          next({
            path: '/app/dashboard',
          });

        }
        // If user is trying to access an unprotected page (index, pricing, etc), let them
        else {
          await store.dispatch('getColorPreferences');
          store.commit('setLoading', false);
          next();
        }

      }
    } else {
      window.location = 'https://' + import.meta.env.VITE_HOSTNAME;
    }
  }
});

const app = createApp(App);

let primaryColors = store.state.colors.primary;

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENV,
  dsn: 'https://4d1597d7e25d4033a93321bc7aea2945@o4504731194097664.ingest.us.sentry.io/4504731198095360',
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: 'light',
      autoInject: false,
      showBranding: false,
      themeLight: {
        accentBackground: `rgb(${primaryColors.red}, ${primaryColors.green}, ${primaryColors.blue})`,
      },
    }),
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['localhost', import.meta.env.VITE_HOSTNAME, /^\//],
  // beforeSend(event, hint) {
  // 	if (event.exception && event.event_id) {
  // 		Sentry.showReportDialog({
  // 			eventId: event.event_id,
  // 		});
  // 	}
  // 	return event;
  // },
  tracesSampleRate: 1.0,
  logErrors: true,
  trackComponents: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router)
  .use(store)
  .use(VueKonva)
  .use(ConfigProvider)
  .use(Button)
  .use(Typography)
  .use(Spin)
  .use(Result)
  .use(Tag)
  .use(Card)
  .use(Empty)
  .use(Radio)
  .use(Tooltip)
  .use(Affix)
  .use(Menu)
  .use(Dropdown)
  .use(Row)
  .use(Col)
  .use(Form)
  .use(Tabs)
  .use(Upload)
  .use(Select)
  .use(Slider)
  .use(Space)
  .use(Input)
  .use(InputNumber)
  .use(Breadcrumb)
  .use(Table)
  .use(Popover)
  .use(Checkbox)
  .use(Skeleton)
  .use(Modal)
  .use(Switch)
  .use(List)
  .use(Statistic)
  .use(FloatButton)
  .use(Steps)
  .use(Alert)
  .use(Progress)
  .use(InstantSearch)
  .use(VueReCaptcha, {
    siteKey: '6Lct7Y0jAAAAABbO6f_S2Vixg0-QqkNwUvfbTnRp',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  })
  .mount('#app');

export { app, router, store };
