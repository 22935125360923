import { httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { getFbFunctions } from './development';

export async function addAgent(context, data) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    const generateCustomToken = httpsCallable(functions, 'generateCustomToken');
    let tokenData = (await generateCustomToken(data)).data;

    console.log(tokenData);
    if (tokenData && tokenData.token) {
      return tokenData;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}
