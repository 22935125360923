import { httpsCallable } from 'firebase/functions';
import { getFbFunctions } from './development';
import { UAParser } from 'ua-parser-js';
import * as Sentry from '@sentry/browser';
import { collection, addDoc, doc, getDoc, Timestamp, getFirestore } from 'firebase/firestore';
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
import { minimatch } from 'minimatch';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  deleteUser,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
  updateProfile,
  onAuthStateChanged,
  SAMLAuthProvider,
  signInWithPopup,
  OAuthProvider,
  reauthenticateWithPopup,
  reload,
  linkWithCredential,
  getIdToken,
  getAuth,
  signInWithCustomToken,
} from 'firebase/auth';

import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check';

import { firebaseConfig } from '../firebaseConfig';
import { initializeApp, getApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

const translateError = (code) => {
  switch (code) {
  case 'auth/invalid-email':
    return 'Incorrect username or password.';
  case 'auth/invalid-password':
    return 'Incorrect username or password.';
  case 'auth/insufficient-permission':
    return 'Incorrect username or password.';
  case 'auth/invalid-credential':
    return 'Incorrect username or password.';
  case 'auth/user-not-found':
    return 'Incorrect username or password.';
  case 'auth/wrong-password':
    return 'Incorrect username or password.';
  case 'auth/web-storage-unsupported':
    return 'This browser is not supported or 3rd party cookies may be disabled.';
  case 'auth/popups-blocked':
    return 'Popups are blocked. Please enable popups to login.';
  case 'auth/cancelled-popup-request':
    return 'Login request was cancelled.';
  case 'auth/account-exists-with-different-credential':
    return 'This email is already in use. Please login with the correct provider.';
  case 'Email not verified. Please check your email for a verification code or contact support.':
    return 'Email not verified. Please check your email for a verification code or contact support.';
  case 'Email not verified. Please verify before logging in.':
    return 'Email not verified. Please verify before logging in.';
  case 'Domain not authorized':
    return 'You are not authorized to login to this domain. Please contact your team administrator.';
  case 'Unable to send verification email. Please try again later.':
    return 'Unable to send verification email. Please try again later.';
  default:
    return 'An error has occurred. Please try again.';
  }
};

var googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
});
var githubProvider = new GithubAuthProvider();
githubProvider.setCustomParameters({
  prompt: 'select_account',
});

var microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'select_account',
  tenant: 'common',
});

async function internalAuthTrigger(
  message,
  redirect,
  router,
  store,
  provider,
  hint,
  token,
) {
  const fbApp = getApp(store.state.firebaseApp);
  const auth = getAuth(fbApp);
  const functions = getFbFunctions(fbApp);

  signInWithPopup(auth, provider)
    .then(async (result) => {
      if (result != null && result.user) {
        if (
          store.state.purchaseFlow == 'basic-yearly' ||
          store.state.purchaseFlow == 'basic-monthly'
        ) {
          var authorizedDomains = await store.dispatch('getAuthorizedDomains');
          await store.dispatch('getSearchKey');
          await store.dispatch('getUserPreferences');

          if (minimatch(window.location.hostname, authorizedDomains)) {
            message.loading('Loading Checkout...', 0);
            var success = `https://${import.meta.env.VITE_HOSTNAME}/app/dashboard?subscribe=true`;
            var cancel = `https://${import.meta.env.VITE_HOSTNAME}/app/dashboard?cancel=true`;
            var price =
              store.state.purchaseFlow == 'basic-yearly'
                ? 'price_1O7TtFIWg5hOg6PDzAzt5TWw'
                : 'price_1M66nQIWg5hOg6PDOSVAX9Ng';
            store.dispatch('checkout', { price, success, cancel });
          } else {
            await store.dispatch('logout');
            message.error({
              content: translateError('Domain not authorized'),
              key: 'login',
              duration: 2.5,
            });
          }
        } else {
          if (token && hint && hint == result.user.email) {
            message.loading('Logging in...', 0);
            const acceptInvitation = httpsCallable(
              functions,
              'acceptInvitationLogin',
            );
            var data = {
              token: token,
              origin: store.state.curDomain,
            };
            var invitationResult = await acceptInvitation(data);
            if (invitationResult.data != false) {
              await reload(result.user);
              store.commit('setUser', auth.currentUser);
              await store.dispatch('getTeam');
              let authorizedDomains = await store.dispatch('getAuthorizedDomains');
              if (invitationResult.data.success) {
                if (minimatch(store.state.curDomain, authorizedDomains)) {
                  await store.dispatch('getSearchKey');
                  await store.dispatch('getUserPreferences');

                  if (minimatch(window.location.hostname, authorizedDomains)) {
                    message.destroy();
                    message.success({
                      content: 'Successfully joined team!',
                      key: 'login',
                      duration: 4.5,
                    });
                    router.push('/app/dashboard');
                  } else {
                    await store.dispatch('logout');
                    message.destroy();
                    message.error({
                      content: translateError('Domain not authorized'),
                      key: 'login',
                      duration: 2.5,
                    });
                  }
                } else {
                  store.commit('setUser', null);
                  store.commit('setTeam', null);
                  store.commit('setSearchKey', null);

                  await store.dispatch('logout');
                  message.destroy();
                  message.error({
                    content: translateError('Domain not authorized'),
                    key: 'login',
                    duration: 2.5,
                  });
                }
              } else {
                if (minimatch(window.location.hostname, authorizedDomains)) {
                  message.destroy();
                  router.push('/app/dashboard');
                  message.error({
                    content:
                      'Unable to join team. Please leave your current team before joining another.',
                    key: 'login',
                    duration: 2.5,
                  });
                } else {
                  await store.dispatch('logout');
                  message.destroy();
                  message.error({
                    content:
                      'Unable to join team. Please leave your current team before joining another.',
                    key: 'login',
                    duration: 2.5,
                  });
                }
              }
            } else {
              let authorizedDomains = await store.dispatch('getAuthorizedDomains');
              if (minimatch(window.location.hostname, authorizedDomains)) {
                message.destroy();
                router.push('/app/dashboard');
                message.error({
                  content:
                    'Unable to join team. Please contact your team administrator.',
                  key: 'login',
                  duration: 2.5,
                });
              } else {
                await store.dispatch('logout');
                message.destroy();
                message.error({
                  content:
                    'Unable to join team. Please contact your team administrator.',
                  key: 'login',
                  duration: 2.5,
                });
              }
            }
          } else {
            message.loading('Logging in...', 0);
            store.commit('setUser', result.user);
            await store.dispatch('getTeam');
            let authorizedDomains = await store.dispatch('getAuthorizedDomains');
            await store.dispatch('getSearchKey');
            await store.dispatch('getUserPreferences');

            if (minimatch(window.location.hostname, authorizedDomains)) {
              if (redirect) {
                message.destroy();
                router.push(redirect);
              } else {
                message.destroy();
                router.push('/app/dashboard');
              }
            } else if (store?.state?.checkedDomain?.data?.ssoProvider) {
              const joinSSO = httpsCallable(functions, 'autoJoinSSO');
              const data = {
                token: await getIdToken(auth.currentUser),
              };
              if (await joinSSO(data)) {
                // Reload the user's data
                await store.dispatch('getTeam');
                await store.dispatch('getAuthorizedDomains');

                message.destroy();
                router.push('/app/dashboard');
              } else {
                await store.dispatch('logout');
                message.destroy();
                message.error({
                  content: translateError('Domain not authorized'),
                  key: 'login',
                  duration: 2.5,
                });
              }
            } else {
              await store.dispatch('logout');
              message.destroy();
              message.error({
                content: translateError('Domain not authorized'),
                key: 'login',
                duration: 2.5,
              });
            }
          }
        }
      }
    })
    .catch(async (err) => {
      console.log(err);
      if (
        err.code === 'auth/account-exists-with-different-credential'
        && (provider.providerId.startsWith('oidc') || provider.providerId.startsWith('saml'))
      ) {
        const email = err.customData.email;
        const pendingCredential = provider.providerId.startsWith('oidc') ?
          OAuthProvider.credentialFromError(err)
          : SAMLAuthProvider.credentialFromError(err);

        // Fetch the sign-in methods for the email
        const methods = await fetchSignInMethodsForEmail(auth, email);

        // Display the modal
        Modal.error({
          title: 'Account Exists',
          content: h('div', null, [
            h('p', null, 'An account with this email already exists with a different sign-in method.'),
            h('p', null, 'Please log in with your previous account to link to the new account.'),
          ]),
          maskClosable: true,
          onOk: async () => {
            try {
              // Sign in with the existing provider
              if (methods.includes('google.com')) {
                await signInWithPopup(auth, googleProvider);
              } else if (methods.includes('github.com')) {
                await signInWithPopup(auth, githubProvider);
              } else if (methods.includes('microsoft.com')) {
                await signInWithPopup(auth, microsoftProvider);
              } else {
                throw new Error('No supported sign-in methods found.');
              }

              // Link the pending credential
              const user = auth.currentUser;
              await linkWithCredential(user, pendingCredential);
              message.success({ content: 'Accounts successfully linked.', duration: 2.5 });
              router.push(redirect || '/app/dashboard');
            } catch (linkError) {
              message.error({ content: `Error linking account: ${linkError.message}`, duration: 2.5 });
            }
          },
        });
      } else {
        message.error({ content: translateError(err.code), key: 'login', duration: 2.5 });
      }
    });
}

export async function authState(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      context.commit('setUser', user);
      await context.dispatch('getTeam');
      await context.dispatch('getSearchKey');

      Sentry.setUser({
        email: user.email || undefined,
        id: user.uid || undefined,
        username: user.displayName || undefined,
      });

    } else {
      context.commit('setUser', null);
      context.commit('setTeam', null);
      context.commit('setSearchKey', null);

      if (window.location.pathname.startsWith('/app')) {
        window.location.href = '/auth/login?instant=false&redirect=' + window.location.pathname;
      }

      Sentry.setUser(null);
    }
  });
}

export async function signup(context, { email, password }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  const response = await createUserWithEmailAndPassword(auth, email, password);
  if (response && response.user) {
    if (
      context.state.purchaseFlow == 'basic-yearly' ||
      context.state.purchaseFlow == 'basic-monthly'
    ) {
      var redirectURL =
        context.state.purchaseFlow == 'basic-yearly'
          ? `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic&yearly=true`
          : `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic`;
      const changeLink = httpsCallable(functions, 'verifyEmailLink');
      let data = {
        email: email,
        url: redirectURL,
      };
      let linkResult = await changeLink(data);
      if (linkResult.data != false && linkResult.data != 'false') {
        await addDoc(collection(db, 'mail'), {
          to: email,
          template: {
            name: 'signup-verification',
            data: {
              link: linkResult.data,
            },
          },
          ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
      } else {
        throw new Error('verification email failed');
      }
    } else {
      const changeLink = httpsCallable(functions, 'verifyEmailLink');
      let data = {
        email: email,
      };
      let linkResult = await changeLink(data);
      if (linkResult.data != false && linkResult.data != 'false') {
        await addDoc(collection(db, 'mail'), {
          to: email,
          template: {
            name: 'signup-verification',
            data: {
              link: linkResult.data,
            },
          },
          ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
      } else {
        throw new Error('verification email failed');
      }
    }
  } else {
    throw new Error('signup failed');
  }
}

export async function signupWithInvitation(
  context,
  { email, password, token },
) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const functions = getFbFunctions(fbApp);

  const response = await createUserWithEmailAndPassword(auth, email, password);
  if (response && response.user) {
    const acceptInvitation = httpsCallable(functions, 'acceptInvitation');
    var data = {
      token: token,
      origin: context.state.curDomain,
    };
    var invitationResult = await acceptInvitation(data);
    if (invitationResult.data != false) {
      await reload(response.user);
      context.commit('setUser', auth.currentUser);
      await context.dispatch('getTeam');
      await context.dispatch('getAuthorizedDomains');

      if (minimatch(context.state.curDomain, context.state.authorizedDomains)) {
        await context.dispatch('getSearchKey');
        await context.dispatch('getUserPreferences');

        return {
          success: true,
        };
      } else {
        context.commit('setUser', null);
        context.commit('setTeam', null);
        context.commit('setSearchKey', null);

        return {
          success: false,
          message: 'Domain not authorized',
        };
      }
    } else {
      await reload(response.user);
      context.commit('setUser', auth.currentUser);
      return {
        success: false,
        message: 'Invitation failed',
      };
    }
  } else {
    return {
      success: false,
      message: 'Signup failed',
    };
  }
}

export async function login(context, { email, password }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  try {
    var response = await signInWithEmailAndPassword(auth, email, password);

    if (response && response.user && response.user.emailVerified) {
      context.commit('setUser', response.user);
      await context.dispatch('getTeam');
      await context.dispatch('getAuthorizedDomains');

      if (minimatch(context.state.curDomain, context.state.authorizedDomains)) {
        await context.dispatch('getSearchKey');
        await context.dispatch('getUserPreferences');

        return {
          success: true,
        };
      } else {
        context.commit('setUser', null);
        context.commit('setTeam', null);
        context.commit('setSearchKey', null);

        return {
          success: false,
          message: 'Domain not authorized',
        };
      }
    } else if (response && response.user && !response.user.emailVerified) {
      const changeLink = httpsCallable(functions, 'verifyEmailLink');
      var data = {
        email: email,
      };
      if (
        context.state.purchaseFlow == 'basic-yearly' ||
        context.state.purchaseFlow == 'basic-monthly'
      ) {
        var redirectURL =
          context.state.purchaseFlow == 'basic-yearly'
            ? `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic&yearly=true`
            : `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic`;
        data.url = redirectURL;

        let linkResult = await changeLink(data);
        if (linkResult.data != false && linkResult.data != 'false') {
          await addDoc(collection(db, 'mail'), {
            to: email,
            template: {
              name: 'login-verify',
              data: {
                link: linkResult.data,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
          return {
            success: false,
            message:
              'Email not verified. Please check your email for a verification code or contact support.',
          };
        } else {
          return {
            success: false,
            message:
              'Unable to send verification email. Please try again later.',
          };
        }
      } else {
        let linkResult = await changeLink(data);
        if (linkResult.data != false && linkResult.data != 'false') {
          await addDoc(collection(db, 'mail'), {
            to: email,
            template: {
              name: 'login-verify',
              data: {
                link: linkResult.data,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
          return {
            success: false,
            message:
              'Email not verified. Please check your email for a verification code or contact support.',
          };
        } else {
          return {
            success: false,
            message:
              'Unable to send verification email. Please try again later.',
          };
        }
      }
    } else {
      return {
        success: false,
        message: 'Login failed',
      };
    }
  } catch (error) {
    if (error.code) {
      return {
        success: false,
        message: error.code,
      };
    } else {
      return {
        success: false,
        message: error,
      };
    }
  }
}

export async function loginWithInvitation(context, { email, password, token }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const functions = getFbFunctions(fbApp);

  try {
    var response = await signInWithEmailAndPassword(auth, email, password);
    if (response && response.user && token) {
      const acceptInvitation = httpsCallable(
        functions,
        'acceptInvitationLogin',
      );
      var data = {
        token: token,
        origin: context.state.curDomain,
      };
      var invitationResult = await acceptInvitation(data);
      if (invitationResult.data != false) {
        await reload(response.user);
        context.commit('setUser', auth.currentUser);
        await context.dispatch('getTeam');
        let authorizedDomains = await context.dispatch('getAuthorizedDomains');
        if (invitationResult.data.success) {
          if (minimatch(context.state.curDomain, authorizedDomains)) {
            await context.dispatch('getSearchKey');
            await context.dispatch('getUserPreferences');

            if (minimatch(window.location.hostname, authorizedDomains)) {
              return {
                success: true,
              };
            } else {
              context.dispatch('logout');
              return {
                success: false,
                message: 'Domain not authorized',
              };
            }
          } else {
            context.commit('setUser', null);
            context.commit('setTeam', null);
            context.commit('setSearchKey', null);

            await context.dispatch('logout');
            return {
              success: false,
              message: 'Domain not authorized',
            };
          }
        } else {
          if (minimatch(window.location.hostname, authorizedDomains)) {
            return {
              success: false,
              message: 'Already in team',
            };
          } else {
            await context.dispatch('logout');
            return {
              success: false,
              message: 'Already in team',
            };
          }
        }
      } else {
        let authorizedDomains = await context.dispatch('getAuthorizedDomains');
        if (minimatch(window.location.hostname, authorizedDomains)) {
          return {
            success: false,
            message: 'Invitation failed',
          };
        } else {
          await context.dispatch('logout');
          return {
            success: false,
            message: 'Invitation failed',
          };
        }
      }
    } else if (response && response.user) {
      context.commit('setUser', response.user);
      await context.dispatch('getTeam');
      await context.dispatch('getAuthorizedDomains');

      if (minimatch(context.state.curDomain, context.state.authorizedDomains)) {
        await context.dispatch('getSearchKey');
        await context.dispatch('getUserPreferences');

        return {
          success: false,
          message: 'Invitation failed',
        };
      } else {
        context.commit('setUser', null);
        context.commit('setTeam', null);
        context.commit('setSearchKey', null);

        return {
          success: false,
          message: 'Domain not authorized',
        };
      }
    } else {
      return {
        success: false,
        message: 'Login failed',
      };
    }
  } catch (error) {
    if (error.code) {
      return {
        success: false,
        message: error.code,
      };
    } else {
      return {
        success: false,
        message: error,
      };
    }
  }
}

export async function signInWithGoogle(
  context,
  { message, redirect, router, store, hint, token, mode },
) {
  const fbApp = getApp(store.state.firebaseApp);
  const auth = getAuth(fbApp);

  if (hint) {
    googleProvider.setCustomParameters({
      login_hint: hint,
      prompt: 'select_account',
    });
  }

  if (mode == 'redirect') {
    await signInWithRedirect(auth, googleProvider);
  } else {
    return await internalAuthTrigger(
      message,
      redirect,
      router,
      store,
      googleProvider,
      hint,
      token,
    );
  }
}

export async function signInWithGithub(
  context,
  { message, redirect, router, store, hint, token, mode },
) {
  const fbApp = getApp(store.state.firebaseApp);
  const auth = getAuth(fbApp);

  if (hint) {
    githubProvider.setCustomParameters({
      login: hint,
      prompt: 'select_account',
    });
  }

  if (mode == 'redirect') {
    await signInWithRedirect(auth, githubProvider);
  } else {
    return await internalAuthTrigger(
      message,
      redirect,
      router,
      store,
      githubProvider,
      hint,
      token,
    );
  }
}

export async function signInWithMicrosoft(
  context,
  { message, redirect, router, store, hint, token, mode },
) {
  const fbApp = getApp(store.state.firebaseApp);
  const auth = getAuth(fbApp);

  if (hint) {
    microsoftProvider.setCustomParameters({
      login_hint: hint,
      prompt: 'select_account',
      tenant: 'common',
    });
  }
  if (mode == 'redirect') {
    await signInWithRedirect(auth, microsoftProvider);
  } else {
    return await internalAuthTrigger(
      message,
      redirect,
      router,
      store,
      microsoftProvider,
      hint,
      token,
    );
  }
}

export async function signInWithSSO(
  context,
  { message, redirect, router, store, mode, hint, token },
) {
  const fbApp = getApp(store.state.firebaseApp);
  const auth = getAuth(fbApp);

  var providerName = store.state.checkedDomain.data.ssoProvider;
  try {
    var provider;
    if (providerName.startsWith('oidc')) {
      provider = new OAuthProvider(providerName);
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      if (hint) {
        provider.setCustomParameters({
          prompt: 'select_account',
          login_hint: hint,
        });
      }
    } else {
      provider = new SAMLAuthProvider(providerName);
    }

    if (mode == 'redirect') {
      await signInWithRedirect(auth, provider);
    } else {
      return await internalAuthTrigger(
        message,
        redirect,
        router,
        store,
        provider,
        hint,
        token,
      );
    }
  } catch (error) {
    console.log(error);
    message.error({
      content: translateError(error.code),
      key: 'login',
      duration: 2.5,
    });
  }
}

export async function resetPassword(context, { email }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  // get sign in methods for email
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  // if email registered with password, send password reset email
  if (signInMethods.length > 0 && signInMethods.includes('password')) {
    const resetLink = httpsCallable(functions, 'resetLink');
    var data = {
      email: email,
    };
    resetLink(data)
      .then(async (result) => {
        await addDoc(collection(db, 'mail'), {
          to: email,
          template: {
            name: 'password-reset',
            data: {
              link: result.data,
            },
          },
          ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
      })
      .catch(async () => {
        throw new Error('Error sending password reset email.');
      });
  }
}

export async function resetPasswordStep2(context, { code, password }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  // Verify the password reset code is valid.
  verifyPasswordResetCode(auth, code)
    .then((email) => {
      const newPassword = password;

      confirmPasswordReset(auth, code, newPassword)
        .then(async () => {
          const response = await signInWithEmailAndPassword(
            auth,
            email,
            password,
          );
          if (response && response.user) {
            context.commit('setUser', response.user);
            await context.dispatch('getTeam');
          }
          const resetLink = httpsCallable(functions, 'resetLink');
          var data = {
            email: email,
          };
          const ua = new UAParser(navigator.userAgent);
          var device = ua.getResult();
          var ip = 'Unknown';
          var ipify = await fetch('https://api.ipify.org?format=json');
          ip = (await ipify.json()).ip;
          resetLink(data)
            .then(async (result) => {
              await addDoc(collection(db, 'mail'), {
                to: email,
                template: {
                  name: 'password-change',
                  data: {
                    username: email,
                    time: new Date().toLocaleString(),
                    device: device.browser.name + ' on ' + device.os.name,
                    link: result.data,
                    ip: ip,
                  },
                },
                ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
              });
            })
            .catch(async () => {
              await addDoc(collection(db, 'mail'), {
                to: email,
                template: {
                  name: 'password-change',
                  data: {
                    username: email,
                    time: new Date().toLocaleString(),
                    location: 'Some Location',
                    device: 'Some Device',
                    link:
                      'https://' +
                      import.meta.env.VITE_HOSTNAME +
                      '/auth/reset',
                    ip: ip,
                  },
                },
                ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
              });
            });
        })
        .catch(() => {
          throw new Error(
            'Error resetting password. Invalid link or password too weak.',
          );
        });
    })
    .catch(() => {
      throw new Error('Error resetting password. Link expired or invalid.');
    });
}

export async function logout(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);

  context.commit('setUser', null);
  context.commit('setTeam', null);
  context.commit('setSearchKey', null);
  context.commit('setSystemPermissions', null);

  if (auth.currentUser) {
    await signOut(auth);
  }
}

export async function deleteAccount(context, { password }) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  // If user is using SSO or github / google, give login popup to delete account
  if (context.state.user.providerData[0].providerId !== 'password') {
    var provider;
    if (context.state.user.providerData[0].providerId == 'github.com') {
      provider = new GithubAuthProvider();
    } else if (context.state.user.providerData[0].providerId == 'google.com') {
      provider = new GoogleAuthProvider();
    } else if (context.state.user.providerData[0].providerId == 'microsoft.com') {
      provider = new OAuthProvider('microsoft.com');
    } else if (context.state.team !== null && context.state.teamData.ssoProvider !== null) {
      // Get SSO provider
      if (context.state.teamData.ssoProvider == 'saml') {
        provider = new SAMLAuthProvider('saml.rm-' + context.state.team + '-aa');
      } else {
        provider = new OAuthProvider('oidc.rm-' + context.state.team + '-aa');
      }
    } else {
      let providerName = context.state.user.providerData[0].providerId;
      if (providerName.startsWith('oidc')) {
        provider = new OAuthProvider(providerName);
      } else {
        provider = new SAMLAuthProvider(providerName);
      }
    }
    // Reauthenticate with SSO provider
    try {
      await reauthenticateWithPopup(context.state.user, provider);
    } catch (e) {
      throw new Error(e);
    }
  } else {
    // Reauthenticate with password
    try {
      const credential = EmailAuthProvider.credential(
        context.state.user.email,
        password,
      );
      await reauthenticateWithCredential(context.state.user, credential);
    } catch (e) {
      throw new Error(e);
    }
  }

  // Before deleting the account, check if user has any SSO providers or white label domain, and delete those first
  const docRef = doc(db, 'users', context.state.user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    if (data.ssoProvider && data.ssoProvider != null) {
      const deleteProvider = httpsCallable(functions, 'deleteSSOProvider');
      var deleteSSOresult = await deleteProvider();
      if (!deleteSSOresult) {
        throw new Error('Unable to delete SSO provider.');
      }
    }
    if (data.cloudflare_id && data.cloudflare_id !== null) {
      const deleteCloudflareHostname = httpsCallable(
        functions,
        'deleteCloudflareHostname',
      );
      var deleteCloudflareResult = await deleteCloudflareHostname();
      if (!deleteCloudflareResult) {
        throw new Error('Unable to delete Cloudflare hostname.');
      }
    }
  }

  // If user is a member of a team, remove them from the team
  if (context.state.team != null) {
    if (!(await context.dispatch('deleteTeamMember', context.state.user.uid))) {
      throw new Error('Unable to leave team.');
    } else {
      context.commit('setTeam', null);
    }
  }

  await deleteUser(context.state.user)
    .then(() => {
      context.commit('setUser', null);
      context.commit('setTeam', null);
      context.commit('setSearchKey', null);
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
}

export async function changePassword(context, { oldPassword, newPassword }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPassword,
    );
    const result = await reauthenticateWithCredential(
      auth.currentUser,
      credential,
    );
    context.commit('setUser', result.user);
    await context.dispatch('getTeam');
  } catch {
    throw new Error('Incorrect old password. Please try again.');
  }
  updatePassword(auth.currentUser, newPassword)
    .then(async () => {
      const resetLink = httpsCallable(functions, 'resetLink');
      var data = {
        email: auth.currentUser.email,
      };
      const ua = new UAParser(navigator.userAgent);
      var device = ua.getResult();
      var ip = 'Unknown';
      var ipify = await fetch('https://api.ipify.org?format=json');
      ip = (await ipify.json()).ip;
      resetLink(data)
        .then(async (result) => {
          await addDoc(collection(db, 'mail'), {
            to: auth.currentUser.email,
            template: {
              name: 'password-change',
              data: {
                username: auth.currentUser.email,
                time: new Date().toLocaleString(),
                device: device.browser.name + ' on ' + device.os.name,
                link: result.data,
                ip: ip,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
        })
        .catch(async () => {
          await addDoc(collection(db, 'mail'), {
            to: auth.currentUser.email,
            template: {
              name: 'password-change',
              data: {
                username: auth.currentUser.email,
                time: new Date().toLocaleString(),
                location: 'Some Location',
                device: 'Some Device',
                link:
                  'https://' + import.meta.env.VITE_HOSTNAME + '/auth/reset',
                ip: ip,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
        });
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function changeEmail(context, { password, newEmail }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password,
    );
    const result = await reauthenticateWithCredential(
      auth.currentUser,
      credential,
    );
    context.commit('setUser', result.user);
    await context.dispatch('getTeam');
  } catch {
    throw new Error('Incorrect password. Please try again.');
  }
  const changeLink = httpsCallable(functions, 'changeEmailLink');
  var data = {
    oldEmail: auth.currentUser.email,
    newEmail: newEmail,
  };
  const ua = new UAParser(navigator.userAgent);
  var device = ua.getResult();
  var ip = 'Unknown';
  var ipify = await fetch('https://api.ipify.org?format=json');
  ip = (await ipify.json()).ip;
  changeLink(data)
    .then(async (result) => {
      await addDoc(collection(db, 'mail'), {
        to: auth.currentUser.email,
        template: {
          name: 'email-change',
          data: {
            username: auth.currentUser.email,
            newUsername: newEmail,
            time: new Date().toLocaleString(),
            device: device.browser.name + ' on ' + device.os.name,
            ip: ip,
          },
        },
        ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      await addDoc(collection(db, 'mail'), {
        to: newEmail,
        template: {
          name: 'email-verification',
          data: {
            link: result.data,
          },
        },
        ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
    })
    .catch(async (error) => {
      throw new Error(error);
    });
}

export async function changeEmailStep2(context, { code }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);

  checkActionCode(auth, code)
    .then(async () => {
      await applyActionCode(auth, code);

      // If signed in, kill session
      if (context.state.user) {
        await signOut(auth);
        context.commit('setUser', null);
        context.commit('setTeam', null);
        context.commit('setSearchKey', null);
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function revertEmailChange(context, { code }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);

  try {
    var info = await checkActionCode(auth, code);
    await applyActionCode(auth, code);
    if (context.state.user) {
      await signOut(auth);
      context.commit('setUser', null);
      context.commit('setTeam', null);
      context.commit('setSearchKey', null);
    }
    return info.data.email;
  } catch {
    throw new Error(
      'Invalid or expired code. Please try again or contact support.',
    );
  }
}

export async function changeName(context, name) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);

  try {
    await updateProfile(auth.currentUser, {
      displayName: name,
    });
    // refresh user
    const user = await auth.currentUser;
    context.commit('setUser', user);
    await context.dispatch('getTeam');
  } catch (error) {
    throw new Error(error);
  }
}

export async function verifyEmail(context, { code, token }) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp);
  const functions = getFbFunctions(fbApp);

  var info;
  try {
    info = await checkActionCode(auth, code);
  } catch {
    throw new Error(
      'Invalid or expired code. Please try again or contact support.',
    );
  }
  try {
    await applyActionCode(auth, code);

    // If login token is provided, use that
    if (token) {
      const response = await signInWithCustomToken(auth, token);
      if (response && response.user) {
        await context.commit('setUser', response.user);
      }
      // If login failed but user is signed in, revert to action code user
      else if (auth.currentUser) {
        await context.commit('setUser', auth.currentUser);
      }

      await context.dispatch('getTeam');
      await context.dispatch('getSearchKey');
    }
    // If no token provided, use action code user
    else if (auth.currentUser) {
      await context.commit('setUser', auth.currentUser);
      await context.dispatch('getTeam');
      await context.dispatch('getSearchKey');
    }
  } catch {
    if (info && info.data && info.data.email) {
      if (
        context.state.purchaseFlow == 'basic-yearly' ||
        context.state.purchaseFlow == 'basic-monthly'
      ) {
        var redirectURL =
          context.state.purchaseFlow == 'basic-yearly'
            ? `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic&yearly=true`
            : `https://${import.meta.env.VITE_HOSTNAME}/auth/verifyEmail?purchase=basic`;
        const changeLink = httpsCallable(functions, 'verifyEmailLink');
        let data = {
          email: info.data.email,
          url: redirectURL,
        };

        let linkResult = await changeLink(data);
        if (linkResult.data != false && linkResult.data != 'false') {
          await addDoc(collection(db, 'mail'), {
            to: info.data.email,
            template: {
              name: 'signup-verification',
              data: {
                link: linkResult.data,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
          throw new Error(
            'Invalid or expired code. Please check your email for a new code or contact support.',
          );
        } else {
          throw new Error(
            'Unable to verify email. Please try again or contact support.',
          );
        }
      } else {
        const changeLink = httpsCallable(functions, 'verifyEmailLink');
        let data = {
          email: info.data.email,
        };
        let linkResult = await changeLink(data);
        if (linkResult.data != false && linkResult.data != 'false') {
          await addDoc(collection(db, 'mail'), {
            to: info.data.email,
            template: {
              name: 'signup-verification',
              data: {
                link: linkResult.data,
              },
            },
            ttl: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7),
          });
          throw new Error(
            'Invalid or expired code. Please check your email for a new code or contact support.',
          );
        } else {
          throw new Error(
            'Unable to verify email. Please try again or contact support.',
          );
        }
      }
    } else {
      throw new Error(
        'Invalid or expired code. Please try again or contact support.',
      );
    }
  }
}

export async function initializeFirebase() {
  const fbApp = initializeApp(firebaseConfig);

  initializeAppCheck(fbApp, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHAV3_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });

  getPerformance(fbApp);

  // // Attempt to get the app
  // try {
  //   getApp();
  // }
  // // If the app doesn't exist, initialize it
  // catch {
  //   // If we are on a custom domain, use that at the auth domain to allow redirect auth flow
  //   if (context.state.checkedDomain && context.state.checkedDomain.data) {
  //     let newConfig = firebaseConfig;
  //     newConfig.authDomain = context.state.checkedDomain.domain;
  //     initializeApp(newConfig);
  //   }
  //   // Otherwise, use the default firebase config
  //   else {
  //     initializeApp(firebaseConfig);
  //   }
  // }
}
