import { httpsCallable } from 'firebase/functions';
import { getFbFunctions } from './development';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

import { getApp } from 'firebase/app';

function webhookRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'webHooks');
  } else {
    return collection(db, 'users', context.state.user.uid, 'webHooks');
  }
}

export async function sendWebHook(context, data) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const sendWebHook = httpsCallable(functions, 'sendWebHook');
  var result = await sendWebHook(data);
  if (result.data == null) {
    return true;
  } else {
    return false;
  }
}

export async function testWebHook(context, data) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const sendWebHook = httpsCallable(functions, 'testWebHook');
  var result = await sendWebHook(data);
  return result;
}

export async function addWebHook(context, data) {
  try {
    var webhook = await addDoc(webhookRef(context), {
      url: data.url,
      name: data.name,
      events: data.events,
      body: data.body,
      headers: data.headers,
      method: data.method,
    });
    var result = {
      id: webhook.id,
      url: data.url,
      name: data.name,
      events: data.events,
      body: data.body,
      headers: data.headers,
      method: data.method,
    };
    return result;
  } catch {
    return false;
  }
}
export async function editWebHook(context, data) {
  try {
    var id = data.id;
    var webhook = data;
    delete webhook.id;

    await updateDoc(doc(webhookRef(context), id), webhook);

    var result = {
      id: id,
      url: webhook.url,
      name: webhook.name,
      events: webhook.events,
      body: webhook.body,
      headers: webhook.headers,
      method: webhook.method,
    };

    return result;
  } catch {
    return false;
  }
}

export async function deleteWebHook(context, id) {
  const docRef = doc(webhookRef(context), id);
  try {
    await deleteDoc(docRef);
    return true;
  }
  catch {
    return false;
  }
}

export async function getWebHooks(context) {
  // Get list of webhook documents and return them
  const docRef = webhookRef(context);
  const querySnapshot = await getDocs(docRef);

  if (querySnapshot.docs.length > 0) {
    var result = [];

    querySnapshot.forEach((doc) => {
      result.push({
        id: doc.id,
        url: doc.data().url,
        name: doc.data().name,
        events: doc.data().events,
        body: doc.data().body,
        headers: doc.data().headers,
        method: doc.data().method,
        status: doc.data().lastResponse ? doc.data().lastResponse : null,
        statusTime: doc.data().lastSuccess ? doc.data().lastSuccess : null,
      });
    });

    return result;

  } else {
    return false;
  }
}
