import { httpsCallable } from 'firebase/functions';

import {
  collection,
  addDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

import {
  getFbFunctions,
} from './development';

import { getApp } from 'firebase/app';

export async function getStripePortal(
  context,
  returnUrl = `https://${window.location.hostname}/app/settings`,
) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    const portalLink = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
    );

    const { data } = await portalLink({
      returnUrl: returnUrl,
    });
    return data.url;
  } catch (e) {
    throw new Error(e);
  }
}

export async function checkout(context, { price, success, cancel }) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  try {
    const docRef = await addDoc(
      collection(db, 'users', context.state.user.uid, 'checkout_sessions'),
      {
        price: price,
        automatic_tax: true, // Automatically calculate tax based on the customer's address
        allow_promotion_codes: true,
        success_url: success,
        cancel_url: cancel,
      },
    );
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        throw new Error(error);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  } catch (e) {
    throw new Error(e);
  }
}

export async function currentSubscription(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && context.state.userMode == 'team') {
      return 'Team';
    } else {
      let curProducts = [];
      const products = await getDocs(collection(db, 'products'));
      products.forEach((product) => {
        curProducts[product.id] = product.data().name;
      });

      const subsRef = collection(
        db,
        'users',
        context.state.user.uid,
        'subscriptions',
      );

      const q = query(subsRef, where('status', 'in', ['trialing', 'active']));

      let selectedPlan = null;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        selectedPlan = curProducts[doc.data().product.id];
      });

      return selectedPlan;
    }
  } else {
    let curProducts = [];
    const products = await getDocs(collection(db, 'products'));
    products.forEach((product) => {
      curProducts[product.id] = product.data().name;
    });

    const subsRef = collection(
      db,
      'users',
      context.state.user.uid,
      'subscriptions',
    );

    const q = query(subsRef, where('status', 'in', ['trialing', 'active']));

    let selectedPlan = null;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      selectedPlan = curProducts[doc.data().product.id];
    });

    return selectedPlan;
  }
}
