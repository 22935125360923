/**
 * @file consent.js
 * @description This script manages user consent tracking and recording for the Rack Manage platform.
 * It generates a unique user identifier (UUID), stores it in a cookie, and logs consent actions
 * to Firebase Realtime Database. The consent data includes the user's consent action, selected
 * categories, and a timestamp. This is used to integrate with Cookie Script's callback functions
 * when the user accepts or rejects cookies in the Cookie Script banner.
 *
 * Note, this file is imported in the `index.html` file and is not part of the Vue.js application.
 * Additionally, it is not called in the application code but is dynamically executed when/if the
 * CookieScript banner is injected via Google Tag Manager. The callback functions will be set via
 * Google Tag Manager and will call the `consentChanged` function when the user accepts or rejects
 * cookies.
 *
 * Key Features:
 * - Generates secure UUIDs using the browser's crypto API (with a fallback).
 * - Manages user tracking ID cookies with a 1-year expiration.
 * - Sends consent data to Firebase using the Realtime Database REST API.
 * - Exposes the following globally accessible methods:
 *   - `RackManage.getOrCreateTrackingID`: Retrieves or creates a tracking ID for the user.
 *   - `RackManage.consentChanged`: Logs consent actions to Firebase.
 */

/**
 * @function generateSecureUUID
 * @description Generates a cryptographically secure UUID (version 4) using the browser's crypto API.
 * If the crypto API is unavailable, it falls back to a non-secure generation method.
 * @returns {string} A unique UUID string.
 */
function generateSecureUUID() {
  if (window.crypto && window.crypto.getRandomValues) {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);

    array[6] = (array[6] & 0x0f) | 0x40; // Version 4
    array[8] = (array[8] & 0x3f) | 0x80; // Variant 10

    return [...array]
      .map((b, i) => (i === 4 || i === 6 || i === 8 || i === 10 ? '-' : '') + b.toString(16).padStart(2, '0'))
      .join('');
  } else {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

/**
 * @function setCookie
 * @description Sets a cookie with a specified name, value, and expiration time.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 * @param {number} days - The number of days before the cookie expires.
 */
function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/; Secure; SameSite=Lax`;
}

/**
 * @function getCookie
 * @description Retrieves the value of a specified cookie.
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The cookie value, or null if the cookie does not exist.
 */
function getCookie(name) {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].split('=');
    if (key === name) return value;
  }
  return null;
}

/**
 * @function getOrCreateTrackingID
 * @description Retrieves the user's tracking ID from a cookie or generates a new one if it does not exist.
 * Stores the tracking ID in a cookie with a 1-year expiration.
 * @returns {string} The user's tracking ID.
 */
function getOrCreateTrackingID() {
  let trackingID = getCookie('rackmanage_consent_id');
  if (!trackingID) {
    trackingID = generateSecureUUID();
    setCookie('rackmanage_consent_id', trackingID, 365); // Cookie expires in 1 year
  }
  return trackingID;
}

/**
 * @async
 * @function consentChanged
 * @description Logs a user's consent action to Firebase Realtime Database. The function records
 * the tracking ID, consent action, selected categories, and a timestamp.
 * @param {string} consent - The consent action taken by the user (e.g., 'Allow', 'Reject', etc.).
 * @param {Array<string>|null} value - The categories of cookies accepted by the user, if applicable.
 * @throws {Error} Throws an error if the consent data fails to store in Firebase.
 */
async function consentChanged(consent, value = null) {
  const trackingID = getOrCreateTrackingID();

  // Fetch the user's IP address
  const ipifyResponse = await fetch('https://api.ipify.org?format=json');
  const ipData = await ipifyResponse.json();

  const payload = {
    consentAction: consent,
    categories: value || [],
    timestamp: Date.now(),
    page: window.location.href,
    dnt: navigator?.doNotTrack === '1' || false,
    userAgent: navigator.userAgent || 'Unknown',
    ip: ipData.ip || 'Unknown',
  };

  // Firebase Realtime Database REST API endpoint
  const firebaseUrl = `https://rm-consent.europe-west1.firebasedatabase.app/consents/${trackingID}.json`;

  // Make the PUT request to store consent data
  const response = await fetch(firebaseUrl, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error('Failed to store consent data', response);
  }
}

// Create a global namespace if it doesn't already exist
window.RackManage = window.RackManage || {};

// Attach functions to the RackManage namespace
window.RackManage.getOrCreateTrackingID = getOrCreateTrackingID;
window.RackManage.consentChanged = consentChanged;
