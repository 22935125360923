import {
  getFunctions,
  connectFunctionsEmulator,
} from 'firebase/functions';

export function getFbFunctions(fbApp) {
  const functions = getFunctions(fbApp);

  // If we're in emulator mode, connect to the local emulator
  let emulatorMode = import.meta.env.VITE_FIREBASE_EMULATOR === 'true';
  let emulatorPort = import.meta.env.VITE_FIREBASE_EMULATOR_PORT || 5004;

  if (emulatorMode) {
    console.info('Connecting to Firebase Functions emulator on port', emulatorPort);
    connectFunctionsEmulator(functions, '127.0.0.1', emulatorPort);
  }

  return functions;
}
