<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: 'rgb(' + colors.primary.red + ', ' + colors.primary.green + ', ' + colors.primary.blue + ')',
        colorLink: '#000',
        colorLinkHover: 'rgb(' + colors.primary.red + ', ' + colors.primary.green + ', ' + colors.primary.blue + ')',
      },
      algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    }"
  >
    <div
      v-if="appLoading"
      class="page-loading selection:bg-primary/75"
    >
      <div class="loader">
        <svg
          class="circular"
          viewBox="25 25 50 50"
        >
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
    <router-view
      v-else
      class="bg-white selection:bg-primary/75"
    />
  </a-config-provider>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { theme } from 'ant-design-vue';

export default defineComponent({
  data() {
    const store = useStore();

    return {
      appLoading: store.state.appLoading,
      theme,
      darkMode: store.state.userPreferences.darkMode,
      colors: store.state.colors,
    };
  },
  watch: {
    '$store.state.appLoading': function() {
      this.appLoading = this.$store.state.appLoading;
    },
    '$store.state.userPreferences.darkMode': function() {
      this.darkMode = this.$store.state.userPreferences.darkMode;
    },
    '$store.state.colors': function() {
      this.colors = this.$store.state.colors;
    },
  },
  mounted() {
    const store = useStore();
    // Call authAction
    store.dispatch('initializeFirebase').then(() => {
      store.dispatch('authState');
    });

    let hjsBaseURL = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/';

    if (store.state.userPreferences.darkMode) {
      if (!document.body.classList.contains('dark')) {
        document.body.classList.add('dark');
      }
      if (document.getElementById('highlightStylesheet').href != hjsBaseURL + 'styles/dark.min.css') {
        document.getElementById('highlightStylesheet').href = hjsBaseURL + 'styles/dark.min.css';
      }
    } else {
      if (document.body.classList.contains('dark')) {
        document.body.classList.remove('dark');
      }
      if (document.getElementById('highlightStylesheet').href != hjsBaseURL + 'styles/default.min.css') {
        document.getElementById('highlightStylesheet').href = hjsBaseURL + 'styles/default.min.css';
      }
    }
  },
});
</script>
