import { httpsCallable } from 'firebase/functions';
import { getFbFunctions } from './development';
import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

import { getApp } from 'firebase/app';

export async function getTeam(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  if (context.state.user) {
    const docRef = doc(db, 'users', context.state.user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().team && docSnap.data().team != '') {
      const teamRef = doc(db, 'teams', docSnap.data().team);
      const teamSnap = await getDoc(teamRef);
      if (teamSnap.exists() && teamSnap.data().members.includes(context.state.user.uid)) {
        context.commit('setTeam', docSnap.data().team);

        let teamData = teamSnap.data();
        let role = 'viewer';

        if (teamData.editors && teamData.editors.includes(context.state.user.uid)) {
          role = 'editor';
        }

        if (teamData.admins && teamData.admins.includes(context.state.user.uid)) {
          role = 'admin';
        }

        if (teamData.owner == context.state.user.uid) {
          role = 'owner';
        }
        teamData.role = role;

        context.commit('setTeamData', teamData);
      } else {
        context.commit('setTeam', null);
        context.commit('setTeamData', null);
      }
    } else {
      context.commit('setTeam', null);
      context.commit('setTeamData', null);
    }
  } else {
    context.commit('setTeam', null);
    context.commit('setTeamData', null);
  }
}

export async function createTeam(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  const createTeam = httpsCallable(functions, 'createTeam');
  try {
    const result = await createTeam();
    context.commit('setTeam', result.data);
    return result.data;
  } catch {
    return false;
  }
}

export async function inviteUser(context, { email, role }) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      const inviteTeamMember = httpsCallable(functions, 'inviteTeamMember');
      const data = {
        email: email,
        role: role,
      };
      try {
        const result = await inviteTeamMember(data);
        return result.data;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getTeamMembers(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const getMembers = httpsCallable(functions, 'getTeamMembers');
      const result = await getMembers();
      if (result.data) {
        return result.data;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
}

export async function getTeamRole(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      var role = 'viewer';

      if (docSnap.data().editors && docSnap.data().editors.includes(context.state.user.uid)) {
        role = 'editor';
      }

      if (docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
        role = 'admin';
      }

      if (docSnap.data().owner == context.state.user.uid) {
        role = 'owner';
      }
      return role;
    } else {
      return 'none';
    }
  } else {
    return 'none';
  }
}

export async function verifyInvitation(context, token) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  const verifyInvitation = httpsCallable(functions, 'verifyInvitation');
  try {
    var data = {
      token: token,
      origin: context.state.curDomain,
    };

    const result = await verifyInvitation(data);
    return result.data;
  } catch {
    return false;
  }
}

export async function updateTeamMemberRole(context, { userId, newRole }) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      const updateTeamMemberRole = httpsCallable(functions, 'updateTeamMemberRole');
      const data = {
        userId,
        newRole,
      };
      try {
        const result = await updateTeamMemberRole(data);
        return result.data;
      } catch (error) {
        console.log(error);
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function deleteTeamInvitation(context, email) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      const deleteTeamInvitation = httpsCallable(functions, 'deleteTeamInvitation');
      const data = {
        email,
      };
      try {
        const result = await deleteTeamInvitation(data);
        return result.data;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function deleteTeamMember(context, userId) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (
      docSnap.exists()
      && (docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid))
      || userId == context.state.user.uid
    ) {
      const deleteTeamMember = httpsCallable(functions, 'deleteTeamMember');
      const data = {
        userId,
      };
      try {
        const result = await deleteTeamMember(data);
        return result.data;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function deleteTeam(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().owner == context.state.user.uid) {
      const deleteTeam = httpsCallable(functions, 'deleteTeam');
      try {
        const result = await deleteTeam();

        context.commit('setTeam', null);
        context.commit('setTeamData', null);
        context.commit('setUserMode', 'user');
        return result.data;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
