
import {
  updateDoc,
  doc,
  getDoc,
  getFirestore,
  deleteField,
} from 'firebase/firestore';

import { getApp } from 'firebase/app';

export async function changeDisplayPreferences(context, displayPreferences) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  await updateDoc(doc(db, 'users', context.state.user.uid), {
    'preferences.displayMode': displayPreferences,
    'displayPreferences': deleteField(),
  });

  context.commit('setDisplayPreferences', displayPreferences);
}

export async function changeDarkMode(context, darkMode) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  await updateDoc(doc(db, 'users', context.state.user.uid), {
    'preferences.darkMode': darkMode,
    'darkMode': deleteField(),
  });
  context.commit('setDarkMode', darkMode);
}

export async function changeNumbering(context, numbering) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  if (numbering == 'false') {
    numbering = false;
  } else if (numbering == 'true') {
    numbering = true;
  }

  await updateDoc(doc(db, 'users', context.state.user.uid), {
    'preferences.numbering': numbering,
    'numbering': deleteField(),
  });
  context.commit('setNumbering', numbering);
}

export async function changeNumberingDirection(context, numberingDirection) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  await updateDoc(doc(db, 'users', context.state.user.uid), {
    'preferences.numberingDirection': numberingDirection,
    'numberingDirection': deleteField(),
  });
  context.commit('setNumberingDirection', numberingDirection);
}

export async function changeNameOverlay(context, nameOverlay) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  await updateDoc(doc(db, 'users', context.state.user.uid), {
    'preferences.nameOverlay': nameOverlay,
  });
  context.commit('setNameOverlay', nameOverlay);
}

export async function getUserPreferences(context) {
  let pref = {
    darkMode: false,
    displayMode: 'table',
    numbering: false,
    numberingDirection: 'DESC',
    nameOverlay: 2,
  };

  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  const docRef = doc(db, 'users', context.state.user.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let userData = docSnap.data();

    // For old prefences format, check both root level and preferences level
    if (userData?.darkMode == null && userData?.preferences?.darkMode == null) {
      pref.darkMode = false;
    } else {
      pref.darkMode = docSnap.data()?.preferences?.darkMode == null
        ? docSnap.data().darkMode
        : docSnap.data().preferences.darkMode;
    }

    if (userData?.displayPreferences == null && userData?.preferences?.displayMode == null) {
      pref.displayMode = 'table';
    } else {
      pref.displayMode = docSnap.data()?.preferences?.displayMode == null
        ? docSnap.data().displayPreferences
        : docSnap.data().preferences.displayMode;
    }

    if (userData?.numbering == null && userData?.preferences?.numbering == null) {
      pref.numbering = false;
    } else {
      pref.numbering = docSnap.data()?.preferences?.numbering == null
        ? docSnap.data().numbering
        : docSnap.data().preferences.numbering;
    }

    if (userData?.numberingDirection == null && userData?.preferences?.numberingDirection == null) {
      pref.numberingDirection = 'DESC';
    } else {
      pref.numberingDirection = docSnap.data()?.preferences?.numberingDirection == null
        ? docSnap.data().numberingDirection
        : docSnap.data().preferences.numberingDirection;
    }

    // New preferences only stored in preferences level
    if (userData?.preferences?.nameOverlay == null) {
      pref.nameOverlay = 2;
    } else {
      pref.nameOverlay = docSnap.data().preferences.nameOverlay;
    }
  }

  context.commit('setUserPreferences', pref);
}
