import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from 'firebase/storage';

import { getFbFunctions } from './development';
import { httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

export async function getSystemPermissions(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp);

  try {
    const docRef = doc(db, 'system', 'permissions');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let permData = {
        employee: true,
        moderator: docSnap.data().moderators.includes(context.state.user.uid),
      };

      console.log('Permissions:', permData);

      context.commit('setSystemPermissions', permData);

      return true;
    } else {
      console.log('No permissions document found');
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getModeratorQueue(context) {
  // Recursively scan files in global/images/moderation_queue
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  const listRef = ref(storage, 'global/images/moderation_queue');
  const scanFolder = async (folderRef) => {
    const results = [];
    const list = await listAll(folderRef);

    // Process files in the current folder
    for (const item of list.items) {
      const downloadURL = await getDownloadURL(item);
      const metadata = await getMetadata(item);
      results.push({
        name: item.name,
        fullPath: item.fullPath,
        url: downloadURL,
        metadata: metadata,
      });
    }

    // Recursively process subfolders
    for (const subFolder of list.prefixes) {
      const subFolderResults = await scanFolder(subFolder);
      results.push(...subFolderResults);
    }

    return results;
  };

  let results = scanFolder(listRef);
  return results;
}

export async function moderateFile(context, { fileGuid, action, team, user }) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    const moderateFile = httpsCallable(functions, 'moderateFile');
    var result = await moderateFile({ fileGuid, action, team, user });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
